// Module
var code = `<button uib-popover-template="'filter.popover.html'" popover-placement="auto bottom"> <i class="fa fa-filter"></i> <span> <span translate>Generic.Filter</span> <small class="label label-xs label-outline label-success">{{ evaluationFilterController.filterCount }}</small> </span> </button> ${"<" + "script"} type="text/ng-template" id="filter.popover.html"> <div class="filter-popover">
    <base-button ng-click="evaluationFilterController.reset()" btn-context="default" class="clear pull-right">
      <i class="fa fa-times-circle-o"></i>
      <span translate>Generic.Filter.Clear</span>
    </base-button>

    <div class="clearfix"></div>

    <div class="form-group">
      <div class="select-box">
        <select class="form-control"
                ng-options="(type.id) as (type.names | description) for type in evaluationFilterController.types"
                ng-model="evaluationFilterController.filter.type" ng-change="evaluationFilterController.onTypeChange()">
          <option value="" translate>Page.EvaluationList.Filter.AllTypes</option>
        </select>
      </div>
    </div>
    <div class="form-group" ng-if="(evaluationFilterController.filter.type !== null && evaluationFilterController.filter.type !== undefined)">
      <div class="select-box">
        <select class="form-control" ng-model="evaluationFilterController.filter.status"
                ng-options="(status.id) as (status.labels | description) for status in evaluationFilterController.filteredStatuses | orderBy: 'sequence'">
          <option value="" translate>Page.EvaluationList.Filter.AllStatuses</option>
        </select>
      </div>
    </div>
    <div class="form-group">
      <div class="select-box">
        <select class="form-control"
                ng-options="period.id as period.name for period in evaluationFilterController.periods"
                ng-model="evaluationFilterController.filter.period">
          <option value="" translate>Page.EvaluationList.Filter.AllPeriods</option>
        </select>
      </div>
    </div>

    <label class="pull-left" translate>Page.EvaluationList.Filter.ShowMine</label>
    <toggle-input class="pull-right" ng-model="evaluationFilterController.filter.showMine"></toggle-input>

    <div class="clearfix"></div>

    <div class="form-group" ng-if="!evaluationFilterController.filter.showMine">
      <person-typeahead
        input-name="person"
        placeholder="{{ 'Page.EvaluationList.Filter.Person.Placeholder' | translate }}"
        ng-model="evaluationFilterController.filter.person"
        view-classes="'align-right'">
      </person-typeahead>
    </div>
    <div class="form-group" ng-if="(evaluationFilterController.filter.type !== null && evaluationFilterController.filter.type !== undefined)">
      <div class="select-box">
        <select
          class="form-control" ng-model="evaluationFilterController.filter.memberType"
          ng-options="(memberType.id) as (memberType.names | description) for memberType in evaluationFilterController.memberTypes | orderBy: 'sequence'">
          <option value="" translate>Page.EvaluationList.Filter.AllRoles</option>
        </select>
      </div>
    </div>

    <label class="date-label" translate>Page.EvaluationList.Filter.StartDate</label>
    <date-input ng-model="evaluationFilterController.filter.startDate"></date-input>

    <label class="date-label" translate>Page.EvaluationList.Filter.ReminderDate</label>
    <date-input ng-model="evaluationFilterController.filter.reminderDate"></date-input>

    <label class="date-label" translate>Page.EvaluationList.Filter.EndDate</label>
    <date-input ng-model="evaluationFilterController.filter.endDate"></date-input>

    <dice-evaluation-tag-selection
      classes="'m-t-15'"
      ng-model="evaluationFilterController.filter.tags">
    </dice-evaluation-tag-selection>
  </div> ${"<" + "/script"}> `;
// Exports
module.exports = code;