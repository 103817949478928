// Module
var code = `<button uib-popover-template="'column.popover.html'" popover-placement="auto bottom"> <i class="fa fa-columns"></i> <span class="hidden-xs" translate>Page.EvaluationList.Columns</span> </button> ${"<" + "script"} type="text/ng-template" id="column.popover.html"> <div class="filter-popover">
    <query-template-button
      type="queryColumnController.type"
      template="queryColumnController.template"
      get-columns="queryColumnController.getColumns()"
      on-change="queryColumnController.onTemplate({ template })">
    </query-template-button>
  </div>
  <div class="filter-popover">
    <div ng-repeat="group in queryColumnController.groups track by group.code">
      <p class="text-bold" translate="{{ group.name }}"></p>

      <div
        class="row"
        ng-repeat="column in queryColumnController.columns | filter: { group : group.code } track by column.code"
        ng-click="queryColumnController.toggle(column, \$event)">
        <div class="col-xs-4">
          <input type="checkbox" id="{{ column.code }}" name="{{ column.code }}" ng-model="column.visible" class="toggle">
          <label></label>
        </div>
        <div class="col-xs-8" translate="{{ column.name }}"></div>
      </div>
    </div>
  </div> ${"<" + "/script"}> `;
// Exports
module.exports = code;