'use strict';

// Import to make Webpack include favicons for index.tpl.html
require.context('../images/favicons', true, /\.(ico|png|svg|webmanifest|xml)$/);

/**
 * @ngdoc overview
 * @name diceApp
 * @description
 *
 * Main module of the application.
 */
angular
  .module('diceApp', [
    'ui.router', // @uirouter/angularjs
    'ui.bootstrap', // angular-ui-bootstrap
    'ngAnimate', // angular-animate
    'ngCookies', // angular-cookies
    'ngMessages', // angular-messages
    'ngResource', // angular-resource
    'ngSanitize', // angular-sanitize
    'ngQuill', // ng-quill
    'ngTouch', // angular-touch
    'dndLists', // angular-drag-and-drop-lists
    'pascalprecht.translate', // angular-translate
    'jarb-input', // es6/app/forms/validation/jarb
    'ngHandsontable', // handsontable.full
    'ngFileUpload', // ng-file-upload
    'images-resizer', // angular-images-resizer
    'monospaced.elastic' // angular-elastic
  ])
  .config(function ($compileProvider, $qProvider, $locationProvider) {
    // Below are some config settings made after updating to Angular 1.6.

    // Angular 1.6.x enforces some new rules, such as resolving component bindings at the moment $onInit() is called.
    // Some components of Dice call these bindings before $onInit() is called, and they would have become undefined if the new behaviour was enabled.
    // The setting below configures Angular to make component bindings available at the moment the component is created (like it always has been the case).
    $compileProvider.preAssignBindingsEnabled(true);

    // Hide console warnings about unhandled promise rejections.
    // This is required, because some of Dice's 3rd party libraries do not have implemented a reject function for their promises / $q's.
    $qProvider.errorOnUnhandledRejections(false);

    // Make sure the URL prefix is "#" and not "#!".
    // This is required, because mails sent to users before Angular was updated to 1.6.x contain the older link prefix. These links break when the link prefix changes.
    $locationProvider.hashPrefix('');
  })
  .config(function ($provide) {
    // Decorate all angular $uibModal instances with backtick safe closing
    // Preventing users from accidently closing a modal with a misclick or 'escape' button
    $provide.decorator('$uibModal', function ($delegate) {
      const open = $delegate.open;

      $delegate.open = function (options) {
        options = _.extend(options || {}, {
          backdrop: 'static',
          keyboard: false
        });

        return open(options);
      };

      return $delegate;
    });
  })
  .config(function ($httpProvider, $uibTooltipProvider) {
    $httpProvider.interceptors.push('authenticationTokenInterceptor');
    $httpProvider.interceptors.push('authenticationErrorInterceptor');
    $httpProvider.interceptors.push('serverErrorInterceptor');

    $uibTooltipProvider.setTriggers({
      openTrigger: 'closeTrigger'
    });
  })
  .config(function ($translateProvider) {
    $translateProvider.useLoader('languageLoader');
    $translateProvider.useMissingTranslationHandler(
      'missingTranslationHandler'
    );
    // can not use 'sanitize', this will mess up UTF-8 characters. See https://stackoverflow.com/questions/33121843/angular-translate-sanitize-escape
    $translateProvider.useSanitizeValueStrategy([
      'escape',
      'sanitizeParameters'
    ]);
    $translateProvider.useLocalStorage();
  })
  .config(function ($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.otherwise('/login');
    $stateProvider.state('dashboard', {
      url: '/dashboard',
      resolve: {
        customer: function (Customer) {
          return Customer.load();
        }
      },
      controller: function (customer) {
        const widgetStateController = this;
        widgetStateController.customer = customer;
      },
      controllerAs: 'dashboardStateController',
      template:
        '<dice-dashboard customer="dashboardStateController.customer"></dice-dashboard>',
      data: {
        root: true
      }
    });
  })
  .run(function (
    $rootScope,
    $state,
    $window,
    authentication,
    Customer,
    language,
    breadcrumb,
    systemPropertyStore
  ) {
    // Get a csrf token on app start
    authentication.loadCurrent();
    systemPropertyStore.init();

    $rootScope.$on('$stateChangeStart', function (event, toState, toParams) {
      //eslint-disable-line angular/on-watch
      // Ensure the user is logged in or page is public
      const isPublic = _.get(toState, 'data.public');
      if (
        !(isPublic === true || toState.name === 'login') &&
        !authentication.isAuthenticated()
      ) {
        event.preventDefault();
        $state.transitionTo('login', {
          reload: false,
          successUrl: $window.location.href.split('#')[1]
        });
        return;
      }

      // Include page in breadcrumb
      if (_.get(toState, 'data.root', false)) {
        breadcrumb.removeAll();
      }
      breadcrumb.addCrumb(toState, toParams);

      // Exclude the header/footer when template is false
      $rootScope.visible = _.get(toState, 'data.visible', true);
    });

    $rootScope.loading = true;
    Customer.load()
      .then((customer) => {
        language.init(customer.supportedLanguages, customer.defaultLanguage);
        $rootScope.appTitle = customer.title;
      })
      .finally(() => {
        $rootScope.loading = false;
      });

    $rootScope.getTitle = function () {
      return $rootScope.appTitle || 'Evaluation';
    };
  })
  .run(function (Parameter) {
    Parameter.load();
  })
  .run(function (SystemInfo, $rootScope, $templateCache) {
    // Automatically defined by webpack
    // eslint-disable-next-line no-undef
    $rootScope.APP = TE_EV_APP;

    SystemInfo.environment().$promise.then((ENV) => {
      $rootScope.ENV = ENV;
    });

    // Load all templates into the template cache, needed for ng-include in Webpack production builds. Regex needs to be a literal.
    const modules = requireAll(
      require.context('../', true, /^(?!.*index\.tpl\.html$).*\.html$/)
    );

    modules.forEach(function (val) {
      $templateCache.put(val.name, val.tpl);
    });

    function requireAll(requireContext) {
      return requireContext.keys().map(function (val) {
        return {
          tpl: requireContext(val), // Require the file, returning file contents as a string as configured using html-loader.
          name: val.split('/').pop() // Use the file name as template name
        };
      });
    }
  });
