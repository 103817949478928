// Module
var code = `<div class="footer p-b-15"> <nav class="row" role="navigation"> <div class="col-sm-12 d-f justify-content--center gap-10 flex-wrap"> <a uib-popover-template="'debug.info.popover.html'" popover-append-to-body="true" popover-trigger="outsideClick" popover-placement="top" popover-class="customer-body debug-info-popover popover-md" translate="Footer.About" class="clickable" role="tooltip"> About </a> <a ng-href="{{ 'Generic.HelpUrl' | translate }}" target="_blank" translate="Generic.Help"> Help </a> </div> </nav> <div class="row m-t-10 m-b-10"> <div class="col-sm-12 d-f justify-content--center"> <a title="timeedit.com" href="https://www.timeedit.com" target="_blank" class="clickable"> <img ng-src="{{ footerController.timeeditLogoUrl }}" alt="" height="22px"/> </a> </div> </div> </div> <div ng-if="footerController.environment.alert" ng-click="footerController.environment.alert = false;" class="ribbon"> <span>{{ footerController.environment.name }}</span> </div> ${"<" + "script"} type="text/ng-template" id="debug.info.popover.html"> <div class="m-t-10 m-b-15 font-xl-normal">
    <span translate="Footer.About.Environment">Environment</span><span>:</span>
    <span>{{ footerController.environment.name | uppercase }}</span>
  </div>

  <div class="m-b-15">
    <strong>
      <i class="fa fa-desktop m-r-5" aria-hidden="true"></i>
      <span translate="Footer.About.Ui">Frontend (UI)</span>
    </strong>
    <dl>
      <dt translate="Footer.About.Ui.Version">Version</dt>
      <dd>{{ footerController.APP.UI.VERSION }}</dd>
    </dl>
    <dl>
      <dt translate="Footer.About.Ui.BuildTime">Build-Time</dt>
      <dd>{{ footerController.APP.UI.BUILD_TIME | diceDateTime }}</dd>
    </dl>
  </div>

  <div class="m-b-15">
    <strong>
      <i class="fa fa-code m-r-5" aria-hidden="true"></i>
      <span translate="Footer.About.Api">Backend (API)</span>
    </strong>
    <dl>
      <div>
        <dt translate="Footer.About.Ui.Version">Version</dt>
        <dd>{{ footerController.REST.version }}</dd>
      </div>
      <div>
        <dt translate="Footer.About.Api.Status">Status</dt>
        <dd>
          <span translate="{{ footerController.REST.statusLabel }}" translate-default="Unknown">{{ footerController.REST.status }}</span>
          <span ng-switch="footerController.REST.status">
            <i ng-switch-when="UP" class="fa fa-check-circle text-success"></i>
            <i ng-switch-when="DOWN" class="fa fa-exclamation-triangle text-danger"></i>
            <i ng-switch-default class="fa fa-question-circle text-info"></i>
          </span>
        </dd>
      </div>
      <div>
        <dt translate="Footer.About.Api.BuildTime">Build-Time</dt>
        <dd>{{ footerController.REST.buildTime }}</dd>
      </div>
    </dl>
  </div>

  <div>
    <strong>
      <i class="fa fa-th-large m-r-5" aria-hidden="true"></i>
      <span translate="Footer.About.Customer">Client</span>
    </strong>
    <dl>
      <div>
        <dt translate="Footer.About.Customer.Client">Client</dt>
        <dd>{{ footerController.REST.client }}</dd>
      </div>
    </dl>
  </div> ${"<" + "/script"}> `;
// Exports
module.exports = code;