// Module
var code = `<span ng-if="benchmarkLabelController.benchmark.average" class="clickable" uib-tooltip="{{ (benchmarkLabelController.tooltip || 'Page.Report.Benchmarks.Tooltip') | translate }}" uib-popover-template="'benchmark-popover.html'" popover-append-to-body="true" popover-placement="left"> <span ng-if="benchmarkLabelController.positive" class="label label-primary label-outline"> +{{ benchmarkLabelController.diff | number }} </span> <span ng-if="!benchmarkLabelController.positive" class="label label-primary label-outline"> {{ benchmarkLabelController.diff | number }} </span> </span> ${"<" + "script"} type="text/ng-template" id="benchmark-popover.html"> <h3 class="m-t-0" translate="Page.Report.Benchmarks"></h3>
    <p translate="Page.Report.Benchmarks.Description"></p>

    <dl class="dl-horizontal dl-small m-b-0">
        <dt ng-if="benchmarkLabelController.benchmark.filterValue" translate>Page.Benchmarks.FilterValue</dt>
        <dd ng-if="benchmarkLabelController.benchmark.filterValue">{{ benchmarkLabelController.benchmark.filterValue }}</dd>
        <dt translate>Page.Benchmarks.Average</dt>
        <dd>{{ benchmarkLabelController.benchmark.average | number : 1 }}</dd>
        <dt translate>Page.Benchmarks.Evaluations</dt>
        <dd>{{ benchmarkLabelController.benchmark.evaluations }}</dd>
        <dt translate>Page.Benchmarks.Respondents</dt>
        <dd>{{ benchmarkLabelController.benchmark.respondents }}</dd>
        <dt translate>Page.Benchmarks.Date</dt>
        <dd>{{ benchmarkLabelController.benchmark.creationDate | diceDateTime }}</dd>
    </dl> ${"<" + "/script"}>`;
// Exports
module.exports = code;